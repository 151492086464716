"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/* eslint-disable no-plusplus */
var constants_1 = require("@api/constants");
var lawsuits_1 = require("@customTypes/reducers/lawsuits");
var folderConstants_1 = require("@redux/constants/folderConstants");
var timelineConstants_1 = require("./timelineConstants");
var nodeEditable_1 = require("@helpers/select/nodeEditable");
var getInnerTagsDocumentsAndPeople = function (state, elements) {
    var persons = [];
    var documents = [];
    var tags = [];
    elements = elements || state.nodes;
    var nodes = elements.filter(function (element) {
        return !["deadline", "addItem", "stickyNote"].includes(element.type);
    });
    for (var index = 0; index < nodes.length; index++) {
        var node = nodes[index];
        var _a = node.data, _persons = _a.persons, _documents = _a.documents, _tags = _a.tags;
        var _loop_1 = function (z) {
            var person = _persons[z];
            if (!persons.some(function (p) { return p.id === person.id; })) {
                persons.push(person);
            }
        };
        for (var z = 0; z < _persons.length; z++) {
            _loop_1(z);
        }
        var _loop_2 = function (z) {
            var document = _documents[z];
            if (!documents.some(function (p) { return p.id === document.id; })) {
                documents.push(document);
            }
        };
        for (var z = 0; z < _documents.length; z++) {
            _loop_2(z);
        }
        var _loop_3 = function (z) {
            var tag = _tags[z];
            if (!tags.some(function (p) { return p.name === tag.name; })) {
                tags.push(tag);
            }
        };
        for (var z = 0; z < _tags.length; z++) {
            _loop_3(z);
        }
    }
    return {
        timelinePersons: persons,
        timelineDocuments: documents,
        timelineTags: tags,
    };
};
function addChildToSharepointFolder(tree, elementId, children) {
    // Check if the current tree node is the desired element
    if (tree.id === elementId) {
        if (children.length > 0) {
            tree.children = children;
        }
        return tree; // Return the updated tree
    }
    // Check if the current tree node has children
    if (tree.children && tree.children.length > 0) {
        // Iterate through the children to find the desired element
        for (var _i = 0, _a = tree.children; _i < _a.length; _i++) {
            var childElement = _a[_i];
            // Recursively search for the element and add the child to it
            var updatedChild = addChildToSharepointFolder(childElement, elementId, children);
            if (updatedChild) {
                return tree; // Return the updated tree
            }
        }
    }
    return null; // Element not found in this subtree
}
var timelineInitialLoadings = __assign(__assign({}, constants_1.initialLoadings), { siteOptions: false, caseOptions: false, particularsOfClaim: false, recents: false, excel: false, imanage: false, ai: false });
var initialNode = {
    id: "",
    title: "",
    description: "",
    note: null,
    date: null,
    time: null,
    endDate: null,
    endTime: null,
    dateOffset: 0,
    persons: [],
    documents: [],
    tags: [],
    color: null,
    textColor: null,
};
var initialState = {
    timelines: [],
    recentTimelines: [],
    nodes: [],
    edges: [],
    timelinePersons: [],
    timelineDocuments: [],
    timelineTags: [],
    filters: [],
    emailsToValidate: [],
    duplicateNodesToValidate: [],
    handleVisability: true,
    title: "",
    description: "",
    caseId: null,
    siteId: null,
    siteDropdown: [],
    caseDropdown: [],
    groupOrLawsuit: lawsuits_1.INITIALLAWSUIT,
    specificTimelineTags: [],
    loadings: timelineInitialLoadings,
    createElementOpen: false,
    goThroughSplitOpen: false,
    personOpen: false,
    documentOpen: false,
    tagOpen: false,
    tag: null,
    timelineNode: initialNode,
    isUpdatingNode: false,
    emailOpen: false,
    currSplittingEmail: null,
    currSplittingNodeRefference: null,
    splitElements: [],
    removedSplitElements: [],
    publicAuthenticated: false,
    publicUserFirstName: "",
    publicUserLastName: "",
    publicAuthenticatedId: null,
    editable: false,
    hasVisitedPublic: false,
    loading: false,
    showDeadlines: false,
    showThumbnails: false,
    thumbnails: {},
    nodesToMove: new Set(),
    newFileNodeIds: [],
    splitDocumentIndexes: new Set(),
    sharepointTree: {
        name: "",
        id: "",
        isFolder: true,
        children: [],
    },
    collapseSameDates: false,
    isMovingNodes: false,
    folderIdLoading: null,
    bundleDocuments: [],
    availableBundleDocuments: [],
    bundle: null,
    bundleLawyersAndParties: [],
    bundleNegotiationDates: [],
    bundleSettings: { isOpen: false, isNew: false },
    collaborators: new Map(),
    room: "",
    legend: [],
    progresses: [],
    isEditBundleOpen: false,
    promptId: null,
};
function reducer(
// eslint-disable-next-line @typescript-eslint/default-param-last
state, action) {
    var _a, _b, _c, _d, _e, _f;
    var _g;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case timelineConstants_1.GET_TIMELINES_SUCCESS:
            return __assign(__assign({}, state), { timelines: action.timelines, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case timelineConstants_1.GET_RECENT_TIMELINES_SUCCESS:
            return __assign(__assign({}, state), { recentTimelines: action.timelines, loadings: __assign(__assign({}, state.loadings), { recents: false }) });
        case timelineConstants_1.CLEAR_RECENT_TIMELINES:
            return __assign(__assign({}, state), { recentTimelines: [], loadings: __assign(__assign({}, state.loadings), { recents: false }) });
        case timelineConstants_1.POST_TIMELINE_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case timelineConstants_1.PUT_TIMELINE_SUCCESS:
            return __assign(__assign({}, state), { title: action.timeline.title, description: action.timeline.description, specificTimelineTags: action.timeline.tags, siteId: action.timeline.site_id, caseId: action.timeline.case_id, loadings: __assign(__assign({}, state.loadings), { modal: false }) });
        case timelineConstants_1.SHOW_TIMELINE_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: false }), title: action.title, description: action.description }), (action.lawsuit && { groupOrLawsuit: action.lawsuit })), { specificTimelineTags: action.tags, collapseSameDates: action.collapseSameDates, bundle: action.bundle, bundleLawyersAndParties: action.bundleLawyersAndParties, bundleNegotiationDates: action.bundleNegotiationDates, caseId: action.caseId, siteId: action.siteId, promptId: action.promptId });
        case timelineConstants_1.IMPORT_EMAILS_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { modal: false }), emailsToValidate: action.emails });
        case timelineConstants_1.IMPORT_FILES_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { modal: false }), newFileNodeIds: action.newFileNodeIds });
        case timelineConstants_1.IMPORT_FILES_ON_EVENT_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }), timelineNode: __assign(__assign({}, state.timelineNode), { documents: __spreadArray(__spreadArray([], state.timelineNode.documents, true), action.documents, true) }) });
        case timelineConstants_1.PUT_TIMELINE_ELEMENT_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }), nodes: state.nodes.map(function (node) {
                    if (node.id === action.node.id) {
                        action.node.position = node.position;
                        return action.node;
                    }
                    return node;
                }) });
        case timelineConstants_1.UPDATE_INNER_TAGS_DOCUMENTS_AND_PEOPLE:
            return __assign(__assign({}, state), getInnerTagsDocumentsAndPeople(state));
        case timelineConstants_1.DELETE_TIMELINE_ELEMENTS_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { loadings: timelineInitialLoadings }), getInnerTagsDocumentsAndPeople(state, action.nodes)), { timelineNode: initialNode, createElementOpen: false });
        case timelineConstants_1.POST_TIMELINE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)), title: "", description: "", groupOrLawsuit: lawsuits_1.INITIALLAWSUIT, specificTimelineTags: [] });
        case timelineConstants_1.GET_TIMELINES_LOADING:
        case timelineConstants_1.POST_TIMELINE_ELEMENT_LOADING:
        case timelineConstants_1.PUT_TIMELINE_ELEMENT_LOADING:
        case timelineConstants_1.SHOW_TIMELINE_LOADING:
        case timelineConstants_1.CUSTOM_SPLIT_LOADING:
        case timelineConstants_1.IMPORT_EMAILS_LOADING:
        case timelineConstants_1.IMPORT_FILES_LOADING:
        case timelineConstants_1.IMPORT_FILES_ON_EVENT_LOADING:
        case timelineConstants_1.PUT_TIMELINE_LOADING:
        case timelineConstants_1.DOWNLOAD_DOCUMENT_LOADING:
        case timelineConstants_1.DELETE_TIMELINE_ELEMENTS_LOADING:
        case timelineConstants_1.DELETE_TIMELINE_LOADING:
        case timelineConstants_1.GET_DUPLICATE_NODES_LOADING:
        case timelineConstants_1.GET_SHAREPOINT_CASE_DROPDOWN_LOADING:
        case timelineConstants_1.GET_SHAREPOINT_SITE_DROPDOWN_LOADING:
        case timelineConstants_1.SOFT_ADD_TIMELINE_NODES_LOADING:
        case timelineConstants_1.SOFT_DELETE_TIMELINE_NODES_LOADING:
        case timelineConstants_1.TOGGLE_EXPAND_COLLAPSE_LOADING:
        case timelineConstants_1.GET_TIMELINE_ELEMENTS_LOADING:
        case timelineConstants_1.TOGGLE_SHOW_DEADLINE_LOADING:
        case timelineConstants_1.IMPORT_TEMPLATE_LOADING:
        case timelineConstants_1.GET_ELEMENTS_WITH_THUMBNAILS_AND_DEADLINES_LOADING:
        case timelineConstants_1.GET_THUMBNAILS_LOADING:
        case timelineConstants_1.MOVE_NODES_LOADING:
        case timelineConstants_1.TIMELINE_DUPLICATE_LOADING:
        case timelineConstants_1.GET_SHAREPOINT_TREE_LOADING:
        case timelineConstants_1.START_PARTICULARS_OF_CLAIM_LOADING:
        case timelineConstants_1.GET_TIMELINE_LEGEND_LOADING:
        case timelineConstants_1.PUT_TIMELINE_LEGEND_LOADING:
        case timelineConstants_1.IMPORT_EXCEL_TEMPLATE_TIMELINE_LOADING:
        case timelineConstants_1.IMPORT_WORD_TEMPLATE_TIMELINE_LOADING:
        case timelineConstants_1.SPLIT_DOCUMENT_LOADING:
        case timelineConstants_1.POST_SHAREPOINT_TREE_LOADING:
        case timelineConstants_1.TIMELINE_TOGGLE_COLLAPSE_SAME_DATE_LOADING:
        case timelineConstants_1.TIMELINE_POST_STICKY_NOTE_LOADING:
        case timelineConstants_1.TIMELINE_PUT_STICKY_NOTE_LOADING:
        case timelineConstants_1.GENERATE_TIMELINE_BUNDLE_LOADING:
        case timelineConstants_1.DRAG_BUNDLE_DOCUMENTS_LOADING:
        case timelineConstants_1.DELETE_BUNDLE_DOCUMENTS_LOADING:
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_LOADING:
        case timelineConstants_1.GET_TIMELINE_EXCEL_LOADING:
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_OPTIMISTIC_LOADING:
        case timelineConstants_1.POST_BUNDLE_DOCUMENT_LOADING:
        case timelineConstants_1.POST_BUNDLE_LOADING:
        case timelineConstants_1.UPDATE_BUNDLE_LOADING:
        case timelineConstants_1.TIMELINE_DELETE_STICKY_NOTE_LOADING:
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_LOADING:
        case timelineConstants_1.SET_LOADINGS:
        case timelineConstants_1.GET_RECENT_TIMELINES_LOADING:
        case timelineConstants_1.DOWNLOAD_TIMELINE_BUNDLE_LOADING:
        case timelineConstants_1.HANDLE_EXPORT_TO_PDF_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = true, _b)) });
        case timelineConstants_1.TIMELINE_DELETE_STICKY_NOTE_FAILED:
        case timelineConstants_1.TIMELINE_NODE_POSITIONS_FAILED:
        case timelineConstants_1.TIMELINE_NODE_SIZE_FAILED:
        case timelineConstants_1.CLIENT_RECONNECTING:
        case timelineConstants_1.START_PARTICULARS_OF_CLAIM_FAILED:
        case timelineConstants_1.CLIENT_LOST_CONNECTION:
        case timelineConstants_1.WS_CONNECTION_ERROR:
        case timelineConstants_1.WS_VALIDATION_ERROR:
        case timelineConstants_1.GET_SHAREPOINT_TREE_FAILED:
        case timelineConstants_1.GET_TIMELINES_FAILED:
        case timelineConstants_1.GET_TIMELINE_EXCEL_FAILED:
        case timelineConstants_1.POST_TIMELINE_FAILED:
        case timelineConstants_1.POST_TIMELINE_ELEMENT_FAILED:
        case timelineConstants_1.PUT_TIMELINE_FAILED:
        case timelineConstants_1.CUSTOM_SPLIT_FAILED:
        case timelineConstants_1.SHOW_TIMELINE_FAILED:
        case timelineConstants_1.IMPORT_EMAILS_FAILED:
        case timelineConstants_1.IMPORT_FILES_FAILED:
        case timelineConstants_1.IMPORT_FILES_ON_EVENT_FAILED:
        case timelineConstants_1.IMPORT_EXCEL_TEMPLATE_TIMELINE_FAILED:
        case timelineConstants_1.IMPORT_WORD_TEMPLATE_TIMELINE_FAILED:
        case timelineConstants_1.DELETE_TIMELINE_FAILED:
        case timelineConstants_1.DELETE_TIMELINE_SUCCESS:
        case timelineConstants_1.DOWNLOAD_DOCUMENT_FAILED:
        case timelineConstants_1.PUT_TIMELINE_ELEMENT_FAILED:
        case timelineConstants_1.DELETE_TIMELINE_ELEMENTS_FAILED:
        case timelineConstants_1.GET_DUPLICATE_NODES_FAILED:
        case timelineConstants_1.GET_TIMELINE_LEGEND_FAILED:
        case timelineConstants_1.PUT_TIMELINE_LEGEND_FAILED:
        case timelineConstants_1.SOFT_DELETE_TIMELINE_NODES_FAILED:
        case timelineConstants_1.SOFT_ADD_TIMELINE_NODES_FAILED:
        case timelineConstants_1.TOGGLE_EXPAND_COLLAPSE_FAILED:
        case timelineConstants_1.GET_TIMELINE_ELEMENTS_FAILED:
        case timelineConstants_1.TOGGLE_SHOW_DEADLINE_FAILED:
        case timelineConstants_1.TIMELINE_DUPLICATE_FAILED:
        case timelineConstants_1.IMPORT_TEMPLATE_FAILED:
        case timelineConstants_1.GET_ELEMENTS_WITH_THUMBNAILS_AND_DEADLINES_FAILED:
        case timelineConstants_1.GET_THUMBNAILS_FAILED:
        case timelineConstants_1.MOVE_NODES_FAILED:
        case timelineConstants_1.SPLIT_DOCUMENT_FAILED:
        case timelineConstants_1.POST_SHAREPOINT_TREE_FAILED:
        case timelineConstants_1.TIMELINE_TOGGLE_COLLAPSE_SAME_DATE_FAILED:
        case timelineConstants_1.TIMELINE_POST_STICKY_NOTE_FAILED:
        case timelineConstants_1.TIMELINE_PUT_STICKY_NOTE_FAILED:
        case timelineConstants_1.GENERATE_TIMELINE_BUNDLE_FAILED:
        case timelineConstants_1.DRAG_BUNDLE_DOCUMENTS_FAILED:
        case timelineConstants_1.DELETE_BUNDLE_DOCUMENTS_FAILED:
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_FAILED:
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_OPTIMISTIC_FAILED:
        case timelineConstants_1.POST_BUNDLE_DOCUMENT_FAILED:
        case timelineConstants_1.POST_BUNDLE_FAILED:
        case timelineConstants_1.UPDATE_BUNDLE_FAILED:
        case timelineConstants_1.SHOW_TIMELINE_TAG_DROPDOWN_FAILED:
        case timelineConstants_1.GET_RECENT_TIMELINES_FAILED:
        case timelineConstants_1.DOWNLOAD_TIMELINE_BUNDLE_FAILED:
        case timelineConstants_1.HANDLE_EXPORT_TO_PDF_FAILED:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case timelineConstants_1.GET_SHAREPOINT_CASE_DROPDOWN_FAILED:
        case timelineConstants_1.GET_SHAREPOINT_SITE_DROPDOWN_FAILED:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case timelineConstants_1.DOWNLOAD_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case timelineConstants_1.CUSTOM_SPLIT_SUCCESS:
            return __assign(__assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }), nodes: action.nodes, edges: action.edges }), getInnerTagsDocumentsAndPeople(state, action.nodes));
        case timelineConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.title });
        case timelineConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case timelineConstants_1.ADD_LAWSUIT:
            return __assign(__assign({}, state), { groupOrLawsuit: action.groupOrLawsuit });
        case timelineConstants_1.CHANGE_TAGS:
            return __assign(__assign({}, state), { specificTimelineTags: action.tags });
        case timelineConstants_1.SHOW_HANDLES_CHANGE:
            return __assign(__assign({}, state), { handleVisability: !state.handleVisability });
        case timelineConstants_1.SET_TIMELINE_PROMPT_ID:
            return __assign(__assign({}, state), { promptId: action.promptId });
        case timelineConstants_1.CREATE_ELEMENT_CHANGE:
            return __assign(__assign({}, state), { createElementOpen: action.bool });
        case timelineConstants_1.GO_THROUGH_SPLIT_CHANGE:
            return __assign(__assign({}, state), { goThroughSplitOpen: action.bool });
        case timelineConstants_1.TIMELINE_ELEMENT_PERSON_CHANGE:
            return __assign(__assign({}, state), { personOpen: action.bool });
        case timelineConstants_1.TIMELINE_ELEMENT_DOCUMENT_CHANGE:
            return __assign(__assign({}, state), { documentOpen: action.bool });
        case timelineConstants_1.OPEN_EMAIL_CHANGE:
            return __assign(__assign({}, state), { emailOpen: action.bool });
        case timelineConstants_1.POST_TIMELINE_ELEMENT_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }), nodes: action.nodes, edges: action.edges }), getInnerTagsDocumentsAndPeople(state, action.nodes)), { timelineNode: initialNode });
        case timelineConstants_1.SET_TIMELINE_NODE:
            return __assign(__assign({}, state), { timelineNode: action.node, isUpdatingNode: !action.isVertical });
        case timelineConstants_1.RESET_TIMELINE_NODE: {
            var node = __assign(__assign({}, initialNode), { root: action.root, index: action.index, tags: [] });
            return __assign(__assign({}, state), { timelineNode: node, isUpdatingNode: false });
        }
        case timelineConstants_1.CHANGE_TIMELINE_NODE_KEY:
            return __assign(__assign({}, state), { timelineNode: __assign(__assign({}, state.timelineNode), (_c = {}, _c[action.attr] = action.val, _c)) });
        case timelineConstants_1.SET_IS_UPDATING:
            return __assign(__assign({}, state), { isUpdatingNode: action.bool });
        case timelineConstants_1.VALIDATE_EMAILS_CLOSE:
            return __assign(__assign({}, state), { emailsToValidate: [] });
        case timelineConstants_1.VALIDATE_DUPLICATE_NODES_CLOSE:
            return __assign(__assign({}, state), { duplicateNodesToValidate: [] });
        case timelineConstants_1.ADD_CURR_SPLITTING_EMAIL:
            return __assign(__assign({}, state), (!state.currSplittingEmail && {
                currSplittingEmail: action.email,
                currSplittingNodeRefference: state.timelineNode.id,
            }));
        case timelineConstants_1.GET_SHAREPOINT_TREE_SUCCESS:
            return __assign(__assign({}, state), { sharepointTree: action.sharepointTree, loadings: __assign(__assign({}, state.loadings), { modal: false }) });
        case timelineConstants_1.ADD_EMAIL_SPLIT:
            return __assign(__assign({}, state), { splitElements: __spreadArray(__spreadArray([], state.splitElements, true), [action.splitElement], false) });
        case timelineConstants_1.OPEN_TAG:
            return __assign(__assign({}, state), { tagOpen: true, tag: action.tag });
        case timelineConstants_1.CLOSE_TAG:
            return __assign(__assign({}, state), { tagOpen: false });
        case timelineConstants_1.REMOVE_EMAIL_SPLIT:
            return __assign(__assign({}, state), { splitElements: state.splitElements.filter(function (x) { return x !== action.splitElement; }), removedSplitElements: __spreadArray(__spreadArray([], state.removedSplitElements, true), [action.removeIndex], false) });
        case timelineConstants_1.FILTER_TIMELINE:
            // eslint-disable-next-line no-case-declarations
            var newFilter_1 = state.filters.includes(action.filter)
                ? state.filters.filter(function (x) { return x !== action.filter; })
                : __spreadArray(__spreadArray([], state.filters, true), [action.filter], false);
            return __assign(__assign({}, state), { filters: newFilter_1, nodes: state.nodes.map(function (node) {
                    if (["addItem", "custom", "stickyNote"].includes(node.type)) {
                        return node;
                    }
                    var stichedNode = node;
                    var personCheck = stichedNode.data.persons.map(function (p) { return "p".concat(p.id); });
                    var documentCheck = stichedNode.data.documents.map(function (d) { return "d".concat(d.id); });
                    var tagCheck = stichedNode.data.tags.map(function (t) { return t.name; });
                    var check = __spreadArray(__spreadArray(__spreadArray([], personCheck, true), documentCheck, true), tagCheck, true);
                    return __assign(__assign({}, stichedNode), { data: __assign(__assign({}, stichedNode.data), { isHidden: newFilter_1.length === 0
                                ? false
                                : !newFilter_1.every(function (x) { return check.includes(x); }) }) });
                }) });
        case timelineConstants_1.CLEAR_FILTER:
            return __assign(__assign({}, state), { filters: [], nodes: state.nodes.map(function (el) {
                    if (["addItem", "custom", "stickyNote"].includes(el.type)) {
                        return el;
                    }
                    var node = el;
                    node.data.isHidden = false;
                    return el;
                }) });
        case timelineConstants_1.GET_TIMELINE_EXCEL_SUCCESS: {
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { excel: false }) });
        }
        case timelineConstants_1.CLEAR_SPLITTING:
            return __assign(__assign({}, state), { currSplittingEmail: null, currSplittingNodeRefference: null, splitElements: [], removedSplitElements: [] });
        case timelineConstants_1.PUBLIC_ACCESS_TIMELINE_LOADING:
        case timelineConstants_1.SHARE_TIMELINE_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case timelineConstants_1.PUBLIC_ACCESS_TIMELINE_SUCCESS:
            return __assign(__assign({}, state), { publicAuthenticated: true, publicAuthenticatedId: action.timelineId, publicUserFirstName: action.publicUserFirstName, publicUserLastName: action.publicUserLastName, editable: action.editable, loading: false });
        case timelineConstants_1.PUBLIC_ACCESS_TIMELINE_FAILED:
        case timelineConstants_1.SHARE_TIMELINE_FAILED:
            return __assign(__assign({}, state), { loading: false });
        case timelineConstants_1.SET_PUBLIC_TIMELINE_ACCESS_FALSE:
            return __assign(__assign({}, state), { publicAuthenticated: false, publicAuthenticatedId: null });
        case timelineConstants_1.SET_PUBLIC_TIMELINE_VISITED:
            return __assign(__assign({}, state), { hasVisitedPublic: true });
        case timelineConstants_1.CHANGE_SITE_ID:
            return __assign(__assign({}, state), { siteId: action.siteId });
        case timelineConstants_1.CHANGE_CASE_ID:
            return __assign(__assign({}, state), { caseId: action.caseId });
        case timelineConstants_1.TOGGLE_SHOW_DEADLINE:
            return __assign(__assign({}, state), { nodes: action.nodes, edges: action.edges, showDeadlines: !state.showDeadlines, loadings: timelineInitialLoadings });
        case timelineConstants_1.SHARE_TIMELINE_SUCCESS:
            return __assign(__assign({}, state), { loading: false });
        case timelineConstants_1.GET_DUPLICATE_NODES_SUCCESS:
            return __assign(__assign({}, state), { duplicateNodesToValidate: action.duplicateNodes, loadings: timelineInitialLoadings });
        case timelineConstants_1.CREATE_TIMELINE:
            return __assign(__assign({}, state), { title: "", description: "", groupOrLawsuit: lawsuits_1.INITIALLAWSUIT, siteId: null, caseId: null, caseDropdown: [], specificTimelineTags: [] });
        case timelineConstants_1.GET_TIMELINE_ELEMENTS_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { nodes: action.nodes, edges: action.edges, collapseSameDates: action.collapseSameDates, showDeadlines: false }), getInnerTagsDocumentsAndPeople(state, action.nodes)), { loadings: timelineInitialLoadings });
        case timelineConstants_1.TOGGLE_EXPAND_COLLAPSE_SUCCESS:
        case timelineConstants_1.IMPORT_TEMPLATE_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { nodes: action.nodes, edges: action.edges }), getInnerTagsDocumentsAndPeople(state, action.nodes)), { showDeadlines: false, loadings: timelineInitialLoadings });
        case timelineConstants_1.CLEAR_TIMELINE_NODES_AND_EDGES:
            return __assign(__assign({}, state), { nodes: [], edges: [] });
        case timelineConstants_1.GET_SHAREPOINT_CASE_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { caseOptions: false }), caseDropdown: action.caseDropdown });
        case timelineConstants_1.GET_SHAREPOINT_SITE_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { siteOptions: false }), siteDropdown: action.siteDropdown });
        case timelineConstants_1.GET_ELEMENTS_WITH_THUMBNAILS_AND_DEADLINES_SUCCESS: {
            return __assign(__assign({}, state), { nodes: action.nodes, edges: action.edges, showThumbnails: action.showThumbnails, loadings: timelineInitialLoadings });
        }
        case timelineConstants_1.GET_THUMBNAILS_SUCCESS: {
            return __assign(__assign({}, state), { thumbnails: action.thumbnails, loadings: __assign(__assign({}, state.loadings), { thumbnail: false }) });
        }
        case timelineConstants_1.TOGGLE_THUMBNAIL: {
            return __assign(__assign({}, state), { showThumbnails: action.toggle, thumbnails: !action.toggle ? {} : state.thumbnails });
        }
        case timelineConstants_1.CLEAR_STATE: {
            return __assign({}, initialState);
        }
        case timelineConstants_1.MOVE_NODES_ENGAGED: {
            var movingNodes = new Set(__spreadArray(__spreadArray([], action.nodeIds, true), Array.from(state.nodesToMove), true));
            return __assign(__assign({}, state), { nodesToMove: movingNodes, isMovingNodes: true });
        }
        case timelineConstants_1.ADD_NODES_TO_MOVE: {
            var movingNodes = new Set(__spreadArray(__spreadArray([], action.nodeIds, true), Array.from(state.nodesToMove), true));
            return __assign(__assign({}, state), { nodesToMove: movingNodes });
        }
        case timelineConstants_1.REMOVE_NODES_FROM_MOVE: {
            var movingNodes_1 = new Set(__spreadArray([], Array.from(state.nodesToMove), true));
            action.nodeIds.forEach(function (id) { return movingNodes_1.delete(id); });
            return __assign(__assign({}, state), { nodesToMove: movingNodes_1 });
        }
        case timelineConstants_1.CLEAR_MOVE_NODES:
            return __assign(__assign({}, state), { nodesToMove: initialState.nodesToMove, isMovingNodes: false });
        case timelineConstants_1.MOVE_NODES_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, nodes: action.nodes, edges: action.edges });
        case timelineConstants_1.UPDATE_THUMBNAIL: {
            var thumbnailsCloneByJsonStringfy = JSON.parse(JSON.stringify(state.thumbnails));
            Object.values(thumbnailsCloneByJsonStringfy).forEach(function (thumbnail) {
                var index = thumbnail.findIndex(function (t) { return t.id === action.thumbnail.id; });
                if (index >= 0) {
                    thumbnail[index] = action.thumbnail;
                }
            });
            return __assign(__assign({}, state), { thumbnails: thumbnailsCloneByJsonStringfy });
        }
        case timelineConstants_1.TOGGLE_DOCUMENT_SPLIT: {
            var splitDocumentIndexes = new Set(Array.from(state.splitDocumentIndexes));
            if (splitDocumentIndexes.has(action.pageIndex)) {
                splitDocumentIndexes.delete(action.pageIndex);
            }
            else {
                splitDocumentIndexes.add(action.pageIndex);
            }
            return __assign(__assign({}, state), { splitDocumentIndexes: splitDocumentIndexes });
        }
        case timelineConstants_1.CLEAR_DOCUMENT_SPLITS:
            return __assign(__assign({}, state), { splitDocumentIndexes: initialState.splitDocumentIndexes, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case timelineConstants_1.VALIDATE_FILES_CLOSE:
            return __assign(__assign({}, state), { newFileNodeIds: initialState.newFileNodeIds });
        case timelineConstants_1.IMPORT_EXCEL_TEMPLATE_TIMELINE_SUCCESS:
        case timelineConstants_1.IMPORT_WORD_TEMPLATE_TIMELINE_SUCCESS:
            return __assign(__assign({}, state), { nodes: action.nodes, edges: action.edges, loadings: __assign(__assign({}, state.loadings), { modal: false }) });
        case timelineConstants_1.SPLIT_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { nodes: action.nodes, edges: action.edges, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case timelineConstants_1.TIMELINE_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { timelines: action.timeline
                    ? __spreadArray(__spreadArray([], state.timelines, true), [action.timeline], false) : state.timelines, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case timelineConstants_1.POST_SHAREPOINT_TREE_SUCCESS:
            return __assign(__assign(__assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { modal: false }), nodes: action.nodes, edges: action.edges, newFileNodeIds: action.newFileNodeIds }), getInnerTagsDocumentsAndPeople(state, action.nodes)), { emailsToValidate: action.emailsToValidate });
        case timelineConstants_1.TIMELINE_TOGGLE_COLLAPSE_SAME_DATE_SUCCESS:
            return __assign(__assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }), nodes: action.nodes, edges: action.edges, collapseSameDates: action.collapseSameDates, showThumbnails: false }), getInnerTagsDocumentsAndPeople(state, action.nodes));
        case timelineConstants_1.UPDATE_TIMELINE_NODE_DOCUMENT: {
            var isReplacedTD_1 = false;
            var mappedDocuments = state.timelineDocuments.map(function (timelineDocument) {
                if (timelineDocument.id === action.timelineDocument.id) {
                    isReplacedTD_1 = true;
                    return action.timelineDocument;
                }
                return timelineDocument;
            });
            var isReplacedND_1 = false;
            var mappedNodeDocuments = state.timelineNode.documents.map(function (nodeDocument) {
                if (nodeDocument.id === action.nodeDocument.id ||
                    // If the documents is updated before it is saved, it will have id 0
                    (nodeDocument.id === 0 && nodeDocument.title === action.nodeDocument.title)) {
                    isReplacedND_1 = true;
                    return action.nodeDocument;
                }
                return nodeDocument;
            });
            return __assign(__assign({}, state), { timelineNode: __assign(__assign({}, state.timelineNode), { documents: __spreadArray(__spreadArray([], mappedNodeDocuments, true), (isReplacedND_1 ? [] : [action.nodeDocument]), true) }), timelineDocuments: __spreadArray(__spreadArray([], mappedDocuments, true), (isReplacedTD_1 ? [] : [action.timelineDocument]), true), nodes: state.nodes.map(function (node) {
                    if (["addItem", "custom", "deadline", "stickyNote"].includes(node.type)) {
                        return node;
                    }
                    var _node = node;
                    return __assign(__assign({}, _node), { data: __assign(__assign({}, _node.data), { documents: _node.data.documents.map(function (document) {
                                if (document.id === action.timelineDocument.id) {
                                    return action.timelineDocument;
                                }
                                return document;
                            }) }) });
                }) });
        }
        case timelineConstants_1.UPDATE_TIMELINE_NODE_PERSON:
            return __assign(__assign({}, state), { timelinePersons: state.timelinePersons.map(function (timelinePerson) {
                    if (timelinePerson.id === action.nodePerson.id) {
                        return action.nodePerson;
                    }
                    return timelinePerson;
                }), nodes: state.nodes.map(function (node) {
                    if (["addItem", "custom", "deadline", "stickyNote"].includes(node.type)) {
                        return node;
                    }
                    var _node = node;
                    return __assign(__assign({}, _node), { data: __assign(__assign({}, _node.data), { persons: _node.data.persons.map(function (person) {
                                if (person.id === action.nodePerson.id) {
                                    return action.nodePerson;
                                }
                                return person;
                            }) }) });
                }) });
        case timelineConstants_1.TIMELINE_POST_STICKY_NOTE_SUCCESS:
            return __assign(__assign({}, state), { nodes: __spreadArray(__spreadArray([], state.nodes, true), [action.payload.stickyNote], false), loadings: timelineInitialLoadings });
        case timelineConstants_1.TIMELINE_DELETE_STICKY_NOTE_SUCCESS:
            return __assign(__assign({}, state), { nodes: state.nodes.filter(function (node) { return node.id !== action.payload.id; }), loadings: timelineInitialLoadings });
        case timelineConstants_1.TIMELINE_CHANGE_NODES:
            return __assign(__assign({}, state), { nodes: action.nodes });
        case timelineConstants_1.TIMELINE_NODE_SIZE_SUCCESS: {
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (node) {
                    return node.id === action.payload.node.id ? action.payload.node : node;
                }), loadings: timelineInitialLoadings });
        }
        case timelineConstants_1.TIMELINE_NODE_POSITIONS_SUCCESS: {
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (node) {
                    var updatedNode = action.payload.nodes.find(function (n) { return n.id === node.id; });
                    if (updatedNode) {
                        return updatedNode;
                    }
                    return node;
                }), loadings: timelineInitialLoadings });
        }
        case timelineConstants_1.TIMELINE_PUT_STICKY_NOTE_SUCCESS:
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (node) {
                    if (node.id === action.payload.stickyNote.id) {
                        return action.payload.stickyNote;
                    }
                    return node;
                }), loadings: timelineInitialLoadings });
        case timelineConstants_1.GET_SHAREPOINT_TREE_FOLDER_LOADING:
            return __assign(__assign({}, state), { folderIdLoading: action.folderId });
        case timelineConstants_1.GET_SHAREPOINT_TREE_FOLDER_SUCCESS:
            return __assign(__assign({}, state), { sharepointTree: addChildToSharepointFolder(state.sharepointTree, action.folderId, action.folderContent) || state.sharepointTree, folderIdLoading: null });
        case timelineConstants_1.GET_SHAREPOINT_TREE_FOLDER_FAILED:
            return __assign(__assign({}, state), { folderIdLoading: null });
        case (0, folderConstants_1.postFolderFailed)("timelines"):
        case (0, folderConstants_1.putFolderTitleFailed)("timelines"):
        case (0, folderConstants_1.putFolderLocationFailed)("timelines"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("timelines"):
        case (0, folderConstants_1.deleteFolderFailed)("timelines"):
        case (0, folderConstants_1.duplicateFolderFailed)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("timelines"):
        case (0, folderConstants_1.putFolderLocationLoading)("timelines"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("timelines"):
        case (0, folderConstants_1.putFolderTitleLoading)("timelines"):
        case (0, folderConstants_1.postFolderLoading)("timelines"):
        case (0, folderConstants_1.duplicateFolderLoading)("timelines"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadings] = true, _d)) });
        case (0, folderConstants_1.postFolderSuccess)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: __spreadArray([action.folder], state.timelines, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: state.timelines.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: state.timelines.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: state.timelines.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("timelines"): {
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: state.timelines
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: state.timelines
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.duplicateFolderSuccess)("timelines"):
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, timelines: __spreadArray(__spreadArray([], action.folder, true), state.timelines, true) });
        case timelineConstants_1.SET_TIMELINE_EDITABLE:
            return __assign(__assign({}, state), { editable: action.editable });
        case timelineConstants_1.GENERATE_TIMELINE_BUNDLE_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: action.bundleDocuments, availableBundleDocuments: action.availableBundleDocuments });
        case timelineConstants_1.GET_TIMELINE_BUNDLE_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: action.bundleDocuments, availableBundleDocuments: action.availableBundleDocuments });
        case timelineConstants_1.DRAG_BUNDLE_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: action.bundleDocuments, availableBundleDocuments: action.availableBundleDocuments });
        case timelineConstants_1.DELETE_BUNDLE_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: action.bundleDocuments, availableBundleDocuments: action.availableBundleDocuments });
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_SUCCESS: {
            var bundleDocuments = action.bundleDocument.includedInBundle
                ? state.bundleDocuments.map(function (bundleDocument) {
                    if (bundleDocument.id === action.bundleDocument.id) {
                        return action.bundleDocument;
                    }
                    return bundleDocument;
                })
                : state.bundleDocuments;
            var availableBundleDocuments = !action.bundleDocument.includedInBundle
                ? state.availableBundleDocuments.map(function (bundleDocument) {
                    if (bundleDocument.id === action.bundleDocument.id) {
                        return action.bundleDocument;
                    }
                    return bundleDocument;
                })
                : state.availableBundleDocuments;
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: bundleDocuments, availableBundleDocuments: availableBundleDocuments });
        }
        case timelineConstants_1.UPDATE_BUNDLE_DOCUMENT_OPTIMISTIC_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case timelineConstants_1.CLOSE_BUNDLE_SETTINGS:
            return __assign(__assign({}, state), { bundleSettings: initialState.bundleSettings });
        case timelineConstants_1.TOGGLE_BUNDLE_SETTINGS:
            return __assign(__assign({}, state), { bundleSettings: {
                    isOpen: !state.bundleSettings.isOpen,
                    isNew: action.isNew,
                } });
        case timelineConstants_1.POST_BUNDLE_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundleDocuments: action.bundleDocuments, availableBundleDocuments: action.availableBundleDocuments });
        case timelineConstants_1.POST_BUNDLE_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundle: action.bundle });
        case timelineConstants_1.UPDATE_BUNDLE_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings, bundle: action.bundle, bundleLawyersAndParties: action.bundleLawyersAndParties, bundleNegotiationDates: action.bundleNegotiationDates });
        case timelineConstants_1.ADD_CONNECTED_USERS_CURSOR: {
            var newMap = action.cursors.reduce(function (map, cursor) { return map.set(cursor.id, cursor); }, state.collaborators);
            return __assign(__assign({}, state), { collaborators: newMap });
        }
        case timelineConstants_1.UPDATE_ROOM:
            return __assign(__assign({}, state), { room: action.room });
        case timelineConstants_1.UPDATE_CURSOR_ROOM: {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.room = action.room;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case timelineConstants_1.CLEAR_USER_CURSORS:
            return __assign(__assign({}, state), { collaborators: new Map() });
        case timelineConstants_1.USER_JOIN_ROOM: {
            var newMap = new Map(state.collaborators);
            return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, action.cursor) });
        }
        case timelineConstants_1.USER_UPDATE_POSITION: {
            var cursor = state.collaborators.get(action.socketId);
            if (cursor) {
                cursor.position = { x: action.x, y: action.y };
                var newMap = new Map(state.collaborators);
                return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, cursor) });
            }
            return state;
        }
        case timelineConstants_1.COLLABORATOR_LEAVE_ROOM:
        case timelineConstants_1.REMOVE_CONNECTED_USER: {
            var newMap = new Map(state.collaborators);
            newMap.delete(action.id);
            return __assign(__assign({}, state), { collaborators: newMap });
        }
        case timelineConstants_1.USER_TOGGLE_ACTIVE: {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.active = action.active;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case timelineConstants_1.GET_TIMELINE_LEGEND_SUCCESS:
            return __assign(__assign({}, state), { legend: action.legend, loadings: __assign(__assign({}, state.loadings), { panel: false }) });
        case timelineConstants_1.PUT_TIMELINE_LEGEND_SUCCESS:
            return __assign(__assign({}, state), { legend: state.legend.map(function (l) {
                    if (l.id === action.id) {
                        l.name = action.name;
                    }
                    return l;
                }), loadings: __assign(__assign({}, state.loadings), { mouse: false }) });
        case timelineConstants_1.TIMELINE_GET_EDITED_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (node) {
                    if (action.payload.nodes[node.id]) {
                        return (0, nodeEditable_1.addUserEditingToNode)(node, action.payload.nodes[node.id]);
                    }
                    return node;
                }) });
        case timelineConstants_1.TIMELINE_EDIT_ELEMENTS_STARTED:
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (el) {
                    if ("editing" in el.data && el.data.editing === action.id) {
                        el = (0, nodeEditable_1.removeUserEditingFromNode)(el);
                    }
                    if (action.nodes.includes(el.id)) {
                        return (0, nodeEditable_1.addUserEditingToNode)(el, action.id);
                    }
                    return el;
                }) });
        case timelineConstants_1.TIMELINE_EDIT_ELEMENTS_FINISHED:
            return __assign(__assign({}, state), { nodes: state.nodes.map(function (el) {
                    if (action.nodes.includes(el.id)) {
                        return (0, nodeEditable_1.removeUserEditingFromNode)(el);
                    }
                    return el;
                }) });
        case timelineConstants_1.SHOW_TIMELINE_TAG_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { timelineTags: action.tags });
        case timelineConstants_1.START_PARTICULARS_OF_CLAIM_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { particularsOfClaim: false }) });
        case timelineConstants_1.TIMELINE_DOWNLOAD_PDF_COMPLETED:
        case timelineConstants_1.TIMELINE_GENERATE_DOCUMENT_BUNDLE_COMPLETE:
        case timelineConstants_1.TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_COMPLETE:
        case timelineConstants_1.TIMELINE_MULTI_FILE_UPLOAD_COMPLETE:
        case timelineConstants_1.TIMELINE_MULTI_EMAIL_UPLOAD_COMPLETE:
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_COMPLETE:
            return __assign(__assign({}, state), { progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign({}, action.progress), { state: "completed" });
                    }
                    return progress;
                }) });
        case timelineConstants_1.TIMELINE_DOWNLOAD_PDF_FAILED:
        case timelineConstants_1.TIMELINE_GENERATE_DOCUMENT_BUNDLE_FAILED:
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_FAILED:
        case timelineConstants_1.TIMELINE_MULTI_FILE_UPLOAD_FAILED:
        case timelineConstants_1.TIMELINE_MULTI_EMAIL_UPLOAD_FAILED:
        case timelineConstants_1.TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_FAILED: {
            return __assign(__assign({}, state), { progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign({}, action.progress), { state: "failed" });
                    }
                    return progress;
                }) });
        }
        case timelineConstants_1.TIMELINE_DOWNLOAD_PDF_PROGRESS:
        case timelineConstants_1.TIMELINE_GENERATE_DOCUMENT_BUNDLE_PROGRESS:
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_PROGRESS:
        case timelineConstants_1.TIMELINE_MULTI_FILE_UPLOAD_PROGRESS:
        case timelineConstants_1.TIMELINE_MULTI_EMAIL_UPLOAD_PROGRESS:
        case timelineConstants_1.TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_PROGRESS: {
            /**
             * Jeg gør det her så når folk joiner socket rummet, får de stadigvæk progress updates
             * Det er en simplere løsning end at gemme progress'en i en redis state 💯
             */
            var index = state.progresses.findIndex(function (progress) {
                return progress.jobId === action.progress.jobId &&
                    progress.name === action.progress.name;
            });
            var progresses = __spreadArray([], state.progresses, true);
            var progressState = ((_g = progresses[index]) === null || _g === void 0 ? void 0 : _g.state) || "started";
            var progress = __assign(__assign({}, action.progress), { state: progressState === "failed" ? "failed" : "started" });
            if (index < 0) {
                progresses.push(progress);
            }
            else {
                progresses.splice(index, 1, progress);
            }
            return __assign(__assign({}, state), { progresses: progresses });
        }
        case timelineConstants_1.TIMELINE_DOWNLOAD_PDF_STARTED:
        case timelineConstants_1.TIMELINE_GENERATE_DOCUMENT_BUNDLE_STARTED:
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_STARTED:
        case timelineConstants_1.TIMELINE_MULTI_FILE_UPLOAD_STARTED:
        case timelineConstants_1.TIMELINE_MULTI_EMAIL_UPLOAD_STARTED:
        case timelineConstants_1.TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_STARTED:
            return __assign(__assign({}, state), { progresses: __spreadArray(__spreadArray([], state.progresses, true), [__assign(__assign({}, action.progress), { state: "not-started" })], false) });
        case timelineConstants_1.TIMELINE_JURISTIQ_DATE_FINDER_FINISH:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { modal: false }) });
        case timelineConstants_1.TIMELINE_REMOVE_PROGRESS:
            return __assign(__assign({}, state), { progresses: state.progresses.filter(function (progress) {
                    return !(progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name);
                }) });
        case timelineConstants_1.HANDLE_EXPORT_TO_PDF_SUCCESS:
        case timelineConstants_1.DOWNLOAD_TIMELINE_BUNDLE_SUCCESS:
            return __assign(__assign({}, state), { loadings: timelineInitialLoadings });
        case timelineConstants_1.TIMELINE_STOP_LOADER:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)) });
        case timelineConstants_1.TIMELINE_START_LOADER:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = true, _f)) });
        case timelineConstants_1.TOGGLE_EDIT_BUNDLE:
            return __assign(__assign({}, state), { isEditBundleOpen: !state.isEditBundleOpen });
        default:
            return state;
    }
}
exports.default = reducer;
