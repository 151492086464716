"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var objectUtils_1 = require("@helpers/imanage/objectUtils");
var initialImanageLoadings = __assign(__assign({}, constants_1.initialLoadings), { auth: false, folder: false });
var initialState = {
    authentication: "unauthorized",
    user: null,
    library: "",
    loadings: initialImanageLoadings,
    documents: {},
    workspace: null,
    authModal: false,
};
function addLoading(state, action) {
    var _a;
    return __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a));
}
function removeLoading(state, action) {
    var _a;
    if (!action.loadingType)
        return state.loadings;
    return __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = false, _a));
}
function imanageReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "IMANAGE_AUTHENTICATE_LOADING":
        case "SEARCH_IMANAGE_DOCUMENTS_LOADING":
        case "GET_IMANAGE_DISCOVERY_LOADING":
        case "IMANAGE_GET_MATTER_LOADING":
        case "IMANAGE_GET_FOLDER_CHILDREN_LOADING":
        case "IMANAGE_GET_WORKSPACE_CHILDREN_LOADING":
        case "IMANAGE_GET_WORKSPACE_LOADING":
            return __assign(__assign({}, state), { loadings: addLoading(state, action) });
        case "GET_IMANAGE_DISCOVERY_FAILED":
            return __assign(__assign({}, state), { user: null, authentication: "unauthorized", loadings: removeLoading(state, action) });
        case "IMANAGE_AUTHENTICATE_FAILED":
            return __assign(__assign({}, state), { authentication: "no_access", loadings: removeLoading(state, action) });
        case "IMANAGE_GET_MATTER_FAILED":
        case "SEARCH_IMANAGE_DOCUMENTS_FAILED":
        case "SEARCH_IMANAGE_DOCUMENTS_SUCCESS":
        case "IMANAGE_GET_FOLDER_CHILDREN_FAILED":
        case "IMANAGE_GET_WORKSPACE_CHILDREN_FAILED":
        case "IMANAGE_GET_WORKSPACE_FAILED":
            return __assign(__assign({}, state), { loadings: removeLoading(state, action) });
        case "IMANAGE_AUTHENTICATE_SUCCESS":
            return __assign(__assign({}, state), { authentication: "authenticated" });
        case "GET_IMANAGE_DISCOVERY_SUCCESS":
            return __assign(__assign({}, state), { user: action.discovery.user, library: action.discovery.work.preferred_library, authentication: "authenticated", loadings: removeLoading(state, action) });
        case "IMANAGE_GET_WORKSPACE_CHILDREN_SUCCESS": {
            if (!state.workspace) {
                return __assign(__assign({}, state), { loadings: removeLoading(state, action) });
            }
            return __assign(__assign({}, state), { loadings: removeLoading(state, action), workspace: __assign(__assign({}, state.workspace), { children: action.children.reduce(function (children, child) {
                        var _a;
                        if (!state.workspace)
                            return children;
                        var found = (_a = state.workspace.children) === null || _a === void 0 ? void 0 : _a[child.id];
                        children[child.id] = __assign(__assign({}, child), { parent: { id: state.workspace.id, parent: state.workspace.parent }, children: __assign({}, ((found === null || found === void 0 ? void 0 : found.children) || {})) });
                        return children;
                    }, {}) }) });
        }
        case "IMANAGE_GET_FOLDER_CHILDREN_SUCCESS": {
            if (!state.workspace) {
                return __assign(__assign({}, state), { loadings: removeLoading(state, action) });
            }
            return __assign(__assign({}, state), { loadings: removeLoading(state, action), workspace: __assign(__assign({}, state.workspace), { children: (0, objectUtils_1.immutablyChangeObjectByKeys)(state.workspace.children, action.path)(function (folder) {
                        folder.children = action.children.reduce(function (children, child) {
                            var _a;
                            var found = (_a = folder.children) === null || _a === void 0 ? void 0 : _a[child.id];
                            children[child.id] = __assign(__assign(__assign({}, child), { parent: { id: folder.id, parent: folder.parent } }), (child.wstype !== "document" && {
                                children: __assign({}, ((found === null || found === void 0 ? void 0 : found.children) || {})),
                            }));
                            return children;
                        }, {});
                    }) }) });
        }
        case "IMANAGE_GET_WORKSPACE_SUCCESS":
            return __assign(__assign({}, state), { workspace: __assign(__assign({}, action.workspace), { children: {} }), loadings: removeLoading(state, action) });
        case "IMANAGE_GET_WORKSPACE_NOT_FOUND":
            return __assign(__assign({}, state), { workspace: null, loadings: removeLoading(state, action) });
        case "RESET_LOADINGS":
            return __assign(__assign({}, state), { loadings: initialImanageLoadings });
        case "IMANAGE_CLOSE_AUTH_MODAL":
            return __assign(__assign({}, state), { authModal: false });
        case "IMANAGE_OPEN_AUTH_MODAL":
            return __assign(__assign({}, state), { authModal: true });
        default:
            return state;
    }
}
exports.default = imanageReducer;
